angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_edit_contacts_groups', {
            url: '/configuration/contacts/groups/{id}',
            views: {
                "main": {
                    controller: 'ContactsGroupEditCtrl',
                    templateUrl: 'web/main/contacts/contactEditGroup.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                grupos: function (contactService, $stateParams) {
                    return contactService.getContactosGrupos({id : $stateParams.id});
                },
                contactos: function (contactService) {
                    return contactService.getContactoss();
                }
            },
            data: {
                pageTitle: 'Contacts groups'
            }
        });
    })


    .controller('ContactsGroupEditCtrl', function($scope, $window, session, contactService, $state, $stateParams, $uibModal, grupos, contactos, $translate) {
        var that = this;
        that.grupos = {};
        that.contactos = {};
        


        that.checkMobile = ($window.innerWidth < 768);

        that.grupos = grupos.data;
        that.contactos = contactos.data;
    

        that.existeContacto = function (contacto) {
            for (i = 0; i < that.grupos[0].groupUsers.length; i++) {
                if (contacto.id == that.grupos[0].groupUsers[i].id) {
                    return true;
                }
            }
            return false;
        };

        that.deleteContacto = function (contacto) {
            for (i = 0; i < that.grupos[0].groupUsers.length; i++) {
                if (contacto.id == that.grupos[0].groupUsers[i].id) {
                    that.grupos[0].groupUsers.splice(i, 1);
                    return true;
                }
            }
            return false;
        };

        that.actualizarGrupo = function () {
            contactService.updateContactoGrupo(that.grupos[0]).then(function (response) {
                swal($translate.instant('¡Correcto!'), $translate.instant('Se han editado los usuarios de este grupo'), 'success');
                $state.go('main.contacts_groups');
            });
            
            
        };
    });
